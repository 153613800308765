body {
  background-color: #1a1a1a;
  height: 100%;
}

.App {
  height: 100vh;
  text-align: center;
}

.main {
  grid-area: main;
  display: grid;
  height: 100%;
  min-height: 100%;
  grid-template-rows: 1fr 8fr 1fr;
  grid-template-areas:
    'header'
    'main'
    'footer';
}

.header {
  grid-area: header;
}

.appBody {
  grid-area: main;
}

.footer {
  grid-area: footer;
}
