* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/** REACT MODERN CALENDAR DATEPICKER  */

.custom-calendar {
  color: #FFFFFF !important;
  width: 100% !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  background: #1A1A1A !important;
  border: 1px solid #8B40F4 !important;
  border-radius: 0px !important;
}

.custom-calendar-disabled {
  color: #696969 !important;
  width: 100% !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  background: #1A1A1A !important;
  border: 1px solid #696969 !important;
  border-radius: 0px !important;
}

.custom-today-day {
  color: #FFFFFF !important;
}

.custom-today-day::after {
  visibility: hidden; /* hide small border under the text */
}

.Calendar__day {
  color: white !important;
}

.Calendar__monthText {
  color: #8B40F4 !important;
}

.Calendar__yearText {
  color: #8B40F4 !important;
}
